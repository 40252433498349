<template>
	<div class="shadow-[1px_1px_12px_0px_rgb(25_25_25_/_9%)] lg:min-h-[495px]">
		<div
			v-if="product"
			:class="[
				'grid h-full grid-cols-[45%,55%] justify-between gap-2 overflow-hidden rounded-lg px-4 py-2 ',
				'md:flex md:flex-col',
			]"
		>
			<div
				class="z-10 col-span-2 mb-2.5 flex items-start justify-between gap-1 text-xs font-medium md:text-[0.688rem] xl:text-xs"
			>
				<div class="col-span-2 flex gap-1">
					<!-- Chapter tag -->
					<span
						v-if="chapter"
						class="flex items-center whitespace-nowrap rounded bg-text px-1.5 text-white"
						>Chp. {{ chapter.split(' ')[chapter.split.length - 1] }}</span
					>

					<!-- New tag -->
					<span
						v-if="isNewProduct"
						class="flex flex-nowrap items-center whitespace-nowrap rounded bg-alert px-1.5 text-white"
						>New</span
					>
				</div>
				<!-- Pricing tier tag -->
				<span class="tier col-span-3 text-right normal-case">{{
					product.pricingTiers?.name
				}}</span>
			</div>

			<!-- Images -->
			<ShopProductImages
				v-if="product.productMetadata"
				:images="product.productMetadata.productImages"
				:slug="product.productMetadata.slug"
				:product-name="product.name"
			/>

			<div>
				<div>
					<NuxtLink :to="`/products/${product.productMetadata?.slug}`" no-prefetch>
						<p
							class="text-lg font-semibold leading-5 text-text md:text-[1.125rem] md:leading-7"
						>
							{{ product.name }}
						</p>
					</NuxtLink>
					<p class="mb-0.5 text-sm text-text md:text-[15px]">
						{{ product.strains?.lean }}
					</p>
					<p
						v-if="product.strains?.lineage && product.productMetadata?.showLineage"
						class="text-xs leading-tight text-text md:text-[15px]"
					>
						{{ product.strains.lineage }}
					</p>
					<p
						v-if="product.productMetadata?.excludedFromFirstTimeDiscount"
						class="my-1 text-xs font-medium"
					>
						1st time discount does not apply.
					</p>
					<p v-if="product.productMetadata?.dealTag" class="my-1 text-xs font-medium">
						{{ product.productMetadata.dealTag }}
					</p>

					<!-- Price & Rating -->
					<div class="flex h-8 items-center">
						<div v-if="variant" class="flex items-center gap-1">
							<div
								class="whitespace-nowrap pr-1 text-right text-xl font-semibold md:text-2xl"
							>
								${{ variant.price }}
							</div>

							<NuxtLink
								:to="`/products/${product.productMetadata?.slug}#reviews`"
								:aria-label="`View reviews for ${product.name} ${numberOfRatings}`"
								no-prefetch
								class="flex flex-row items-center md:gap-1"
							>
								<RatingIcons
									:rating="averageRating ?? 0"
									:width-height="isMobile ? 4 : 5"
									class="-ml-2 -mt-0.5 scale-90 md:ml-0 md:mt-0 md:scale-100"
								/>
								<p class="text-[0.625rem] md:mt-1 md:text-xs">
									{{ numberOfRatings }}
								</p>
							</NuxtLink>
						</div>
						<p v-else>Loading...</p>
					</div>

					<!-- Potency -->
					<div class="mb-1 flex flex-row gap-2 text-xs font-light md:text-base">
						<p v-if="product.productMetadata?.potencyThc" class="flex flex-row">
							<span class="mr-1 font-medium">THC</span>
							{{ product.productMetadata?.potencyThc }}
						</p>
						<p v-if="product.productMetadata?.totalCannabinoids" class="flex flex-row">
							<span class="mr-1 font-medium">Total</span>
							{{ product.productMetadata?.totalCannabinoids }}
						</p>
					</div>

					<!-- Lean bars -->
					<div class="px-[1px]">
						<LeanBarIndica v-if="product.strains?.leanBarPercentage === 100" />
						<LeanBarIndicaHybrid v-if="product.strains?.leanBarPercentage === 75" />
						<LeanBarHybrid v-if="product.strains?.leanBarPercentage === 50" />
						<LeanBarSativaHybrid v-if="product.strains?.leanBarPercentage === 25" />
						<LeanBarSativa v-if="product.strains?.leanBarPercentage === 0" />
					</div>
				</div>

				<!-- Inputs -->
				<div class="col-span-2 my-2 grid w-full grid-cols-2 items-center gap-1">
					<!-- Pricing tier products -->
					<InputSelect
						v-if="isPricingTierProduct && pricingTierOptions"
						:options="pricingTierOptions"
						text-property="size"
						width="auto"
						:height="inputHeight"
						aria="Product size"
						@change-option="(n) => (variantIndex = n)"
					/>
					<!-- Single products -->
					<InputSelect
						v-if="isSingleProduct"
						:options="createSinglePriceOption()"
						text-property="size"
						width="auto"
						:height="inputHeight"
						aria="Product size"
						@change-option="(n) => (variantIndex = n)"
					/>

					<!-- Grouped products -->
					<InputSelect
						v-if="isGroupedProduct && groupedProductOptions"
						:options="groupedProductOptions"
						text-property="size"
						width="auto"
						:height="inputHeight"
						aria="Product size"
						@change-option="(n) => (variantIndex = n)"
					/>

					<!-- Quantity -->
					<InputQuantity
						:value="quantity"
						width="auto"
						:height="inputHeight"
						aria="Product quantity"
						@update:increment="quantity++"
						@update:decrement="quantity = quantity > 0 ? quantity - 1 : 0"
						@update:value="(v) => (quantity = v)"
					/>
				</div>

				<ButtonSolid
					v-if="variant"
					primary-color
					class="w-full"
					@click="
						{
							if (variant) {
								addingToCart = true;
								addItemToCart(
									variant.id,
									variant.price,
									variant.priceNonDiscounted ?? null,
									variant.size,
									quantity,
									product.pricingTierId ?? null,
									variant.productQuantityMultiplier ?? null,
									variant.grams,
									isConcentrateProduct,
									isGroupedProduct,
									categories,
								);

								gtmProductAddToCart();
							}
						}
					"
				>
					{{ buttonText }}
				</ButtonSolid>
				<ButtonSolid v-else primary-color class="w-full">Loading...</ButtonSolid>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useCartStore } from '../../stores/cartStore';
import useProductOptions from '../../composables/productOptions';
import { computed, watch } from 'vue';
import ButtonSolid from '../ui/ButtonSolid.vue';
import InputSelect from '../ui/InputSelect.vue';
import InputQuantity from '../ui/InputQuantity.vue';
import LeanBarIndica from '../icons/LeanBarIndica.vue';
import LeanBarIndicaHybrid from '../icons/LeanBarIndicaHybrid.vue';
import LeanBarHybrid from '../icons/LeanBarHybrid.vue';
import LeanBarSativaHybrid from '../icons/LeanBarSativaHybrid.vue';
import LeanBarSativa from '../icons/LeanBarSativa.vue';
import RatingIcons from '../ui/RatingIcons.vue';
import ShopProductImages from './ShopProductImages.vue';
import { useWindowSize } from '@vueuse/core';
import handleFrontendError from '../../utils/handleFrontendError';
import { useGtm } from '@gtm-support/vue-gtm';
import type { Product } from '~/types/app.types';

const CartStore = useCartStore();
const { addItemToCart } = CartStore;
const { width } = useWindowSize();

const props = defineProps<{
	product: Product;
	isCrossSell?: boolean;
}>();

const captureAsCrossSell = computed(() => props.isCrossSell);
const isMobile = computed(() => width.value < 768);
const inputHeight = computed(() => (isMobile.value ? '30px' : '36px'));

const isNewProduct = computed(() => {
	if (props.product.productMetadata?.newTag) {
		// expiration date exists
		if (props.product.productMetadata?.newTagExpiration) {
			return (
				new Date(
					props.product.productMetadata?.newTagExpiration
						? props.product.productMetadata.newTagExpiration
						: 0,
				).getTime() > Date.now()
			);
		}

		// no expiration date
		return true;
	}
	return false;
});

const {
	averageRating,
	categories,
	chapter,
	createSinglePriceOption,
	groupedProductOptions,
	isConcentrateProduct,
	isGroupedProduct,
	isPricingTierProduct,
	isSingleProduct,
	numberOfRatings,
	pricingTierOptions,
	quantity,
	variant,
	variantIndex,
} = useProductOptions(props.product);

const buttonText = ref('Add to Cart');
const addingToCart = ref(false);

watch(addingToCart, (newValue) => {
	if (newValue) {
		buttonText.value = 'Item Added!';

		setTimeout(() => {
			addingToCart.value = false;
			buttonText.value = 'Add to Cart';
		}, 800);
	}
});

function gtmProductAddToCart() {
	try {
		useGtm()?.trackEvent({
			event: captureAsCrossSell.value
				? 'related-product-added-to-cart'
				: 'main-shop-product-added-to-cart',
			category: captureAsCrossSell.value ? 'Cross Sells' : 'Shop',
			action: 'Add to Cart',
			value: variant.value?.price,
			productName: props.product.name,
			productSize: variant.value?.size,
			productQuantity: quantity.value,
			noninteraction: false,
		});
	} catch (error) {
		handleFrontendError(error, false);
	}
}
</script>
